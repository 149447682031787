import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import DateRangeModal from './DateRangeModal';

const datePresets = [
  { value: '24hours', label: 'Dernières 24 heures' },
  { value: '7days', label: '7 derniers jours' },
  { value: '30days', label: '30 derniers jours' },
  { value: '1year', label: 'Dernière année' },
  { value: '2024', label: 'Année 2024' },
  { value: '2023', label: 'Année 2023' },
  { value: 'custom', label: '📅 Plage personnalisée' }
];

export default function DateFilter({ value, onChange }) {
  const [showModal, setShowModal] = useState(false);
  const [selectedPreset, setSelectedPreset] = useState('7days');

  // Set default 7-day range on component mount
  useEffect(() => {
    if (!value) {
      const dates = getPresetDates('7days');
      onChange(dates);
    }
  }, []);

  const getPresetDates = (preset) => {
    let end = new Date();
    let start = new Date();

    switch (preset) {
      case '24hours':
        start.setDate(end.getDate() - 1);
        break;
      case '7days':
        start.setDate(end.getDate() - 7);
        break;
      case '30days':
        start.setDate(end.getDate() - 30);
        break;
      case '1year':
        start.setFullYear(end.getFullYear() - 1);
        break;
      case '2024':
        start = new Date('2024-01-01');
        end = new Date('2024-12-31');
        break;
      case '2023':
        start = new Date('2023-01-01');
        end = new Date('2023-12-31');
        break;
      default:
        return null;
    }

    return {
      startDate: start.toISOString().split('T')[0],
      endDate: end.toISOString().split('T')[0]
    };
  };

  const isPresetDates = (value) => {
    if (!value) return false;

    const presets = {
      '24hours': getPresetDates('24hours'),
      '7days': getPresetDates('7days'),
      '30days': getPresetDates('30days'),
      '1year': getPresetDates('1year'),
      '2024': getPresetDates('2024'),
      '2023': getPresetDates('2023'),
    };

    return Object.entries(presets).find(([preset, dates]) =>
      dates.startDate === value.startDate && dates.endDate === value.endDate
    )?.[0];
  };

  const getCurrentLabel = () => {
    if (!value) return datePresets[0];

    const preset = isPresetDates(value);
    if (preset) {
      return datePresets.find(p => p.value === preset);
    }

    // If not a preset, it's a custom range
    const start = new Date(value.startDate);
    const end = new Date(value.endDate);
    const formatDate = (date) => date.toLocaleDateString('fr-FR', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });

    return {
      value: 'custom',
      label: `${formatDate(start)} - ${formatDate(end)}`
    };
  };

  const handleChange = (selected) => {
    if (!selected) {
      const dates = getPresetDates('7days');
      setSelectedPreset('7days');
      onChange(dates);
      return;
    }

    if (selected.value === 'custom') {
      setShowModal(true);
    } else {
      setSelectedPreset(selected.value);
      const dates = getPresetDates(selected.value);
      onChange(dates);
    }
  };

  return (
    <>
      <Select
        value={getCurrentLabel()}
        onChange={handleChange}
        options={datePresets}
        className="text-xs"
        classNamePrefix="select"
        placeholder="Sélectionner une plage de dates..."
        styles={{
          control: (base) => ({
            ...base,
            minHeight: '30px',
            height: '30px'
          }),
          valueContainer: (base) => ({
            ...base,
            height: '30px',
            padding: '0 6px'
          }),
          input: (base) => ({
            ...base,
            margin: '0px'
          }),
          indicatorsContainer: (base) => ({
            ...base,
            height: '30px'
          }),
          menu: (base) => ({
            ...base,
            zIndex: 50
          })
        }}
      />

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center
          justify-center z-[60]">
          <DateRangeModal
            value={value}
            onChange={(dates) => {
              setSelectedPreset('custom');
              onChange(dates);
            }}
            onClose={() => setShowModal(false)}
          />
        </div>
      )}
    </>
  );
}