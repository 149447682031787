import React, { useRef, useState } from 'react';
import useIntersectionObserver from '../hooks/useIntersectionObserver';

function LazyImage({ src, alt, className }) {
  const imgRef = useRef();
  const [isLoaded, setIsLoaded] = useState(false);
  const isVisible = useIntersectionObserver(imgRef, { threshold: 0.1 });

  return (
    <div ref={imgRef} className={`${className} relative bg-gray-100`}>
      {/* Spinner */}
      {isVisible && !isLoaded && (
        <div className="absolute inset-0 flex items-center justify-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500"></div>
        </div>
      )}

      {/* Image */}
      {isVisible && (
        <img
          src={src}
          alt={alt}
          className={`${className} ${isLoaded ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}
          onLoad={() => setIsLoaded(true)}
          loading="lazy"
        />
      )}
    </div>
  );
}

export default LazyImage;