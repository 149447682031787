import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import DateFilter from './DateFilter';
import HeatmapModal from './HeatmapModal';

const i18n = {
  shopcity: 'Ville',
  username: 'Mi Team',
  shopcountry: 'Pays',
  shopname: 'Nom du magasin',
  shopprovince: 'Province',
  shoptype: 'Type de magasin',
  shopsign: 'Enseigne',
  projectname: 'Programme',
  shopzipcode: 'Code postal',
  item: 'Produit, concurrent, emplacement...'
};

export default function Filters({ dimensions, filters, handleFilterChange, allowedColumns, fetchWithAuth }) {
  const [showHeatmap, setShowHeatmap] = useState(false);

  return (
    <div className="space-y-4">
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-1.5">
        <div className="mb-1">
          <label className="block text-xs font-medium text-gray-700 mb-0.5">
            Période
          </label>
          <DateFilter
            value={filters.createdat}
            onChange={(dates) => handleFilterChange('createdat', dates)}
          />
        </div>

        {allowedColumns.filter(column => column !== 'createdat').map((column) => (
          <div key={column} className="mb-1">
            <label className="block text-xs font-medium text-gray-700 mb-0.5">
              {i18n[column] || column.charAt(0).toUpperCase() + column.slice(1)} ({dimensions[column]?.length || 0})
            </label>
            <Select
              key={`select-${column}-${filters[column]?.value || 'empty'}`}
              value={filters[column] || null}
              onChange={(selected) => handleFilterChange(column, selected)}
              options={dimensions[column]}
              isClearable
              isLoading={!dimensions[column]}
              className="text-xs"
              classNamePrefix="select"
              placeholder="Sélectionner..."
              styles={{
                control: (base) => ({
                  ...base,
                  minHeight: '30px',
                  height: '30px'
                }),
                valueContainer: (base) => ({
                  ...base,
                  height: '30px',
                  padding: '0 6px'
                }),
                input: (base) => ({
                  ...base,
                  margin: '0px'
                }),
                indicatorsContainer: (base) => ({
                  ...base,
                  height: '30px'
                }),
                menu: (base) => ({
                  ...base,
                  zIndex: 30
                })
              }}
            />
          </div>
        ))}
      </div>

      <div className="flex items-center justify-between border-t pt-3">
        <label className="relative inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            checked={filters.favorite || false}
            onChange={(e) => handleFilterChange('favorite', e.target.checked)}
            className="sr-only peer"
          />
          <div className="w-11 h-6 bg-gray-200 rounded-full peer
            peer-focus:ring-4 peer-focus:ring-red-300
            dark:peer-focus:ring-red-800
            peer-checked:after:translate-x-full
            peer-checked:after:border-white
            after:content-['']
            after:absolute
            after:top-0.5
            after:left-[2px]
            after:bg-white
            after:border-gray-300
            after:border
            after:rounded-full
            after:h-5
            after:w-5
            after:transition-all
            peer-checked:bg-red-500"
          />
          <span className="ml-3 text-sm font-medium text-gray-700 flex items-center gap-1.5">
            Afficher uniquement les favoris
            <svg
              className="w-4 h-4 text-red-500 fill-current"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="0"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
              />
            </svg>
          </span>
        </label>

        <button
          onClick={() => setShowHeatmap(true)}
          className="flex items-center gap-2 text-sm text-gray-700 hover:text-gray-900"
        >
          <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" stroke="currentColor">
            <rect x="2" y="2" width="20" height="20" rx="2" strokeWidth="2"/>
            <path d="M7 16V8M12 16V4M17 16v-5" strokeWidth="2" strokeLinecap="round"/>
          </svg>
          <span className="hidden md:inline">Voir la fréquence</span>
        </button>
      </div>

      {showHeatmap && (
        <HeatmapModal
          onClose={() => setShowHeatmap(false)}
          filters={filters}
          fetchWithAuth={fetchWithAuth}
        />
      )}
    </div>
  );
}