import React from 'react';
import { useNavigate } from 'react-router-dom';

function Home() {
  const navigate = useNavigate();

  return (
    <div className="max-w-2xl mx-auto p-4">
      <h1 className="text-4xl font-bold text-gray-900 mb-4">
        Bienvenue sur l'application Gallery
      </h1>
      <p className="text-lg text-gray-600">
        Cette application permet de consulter et exporter les photos prises tous les jours sur le terrain par les équipes.
      </p>
      <div className="mt-8">
        <button
          onClick={() => navigate('/gallery')}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
        >
          Commencer
        </button>
      </div>
    </div>
  );
}

export default Home;