// Base configuration for all environments
const config = {
  development: {
    API_BASE_URL: 'http://localhost:3001'
  },
  production: {
    API_BASE_URL: 'https://th-images-viewer.omagad.tech' // Empty string for relative paths in production
  },
  test: {
    API_BASE_URL: 'http://localhost:3001'
  }
};

// Get current environment, fallback to 'development' if not set
const env = process.env.NODE_ENV || 'development';

// Export the configuration for the current environment
export const API_BASE_URL = config[env].API_BASE_URL;

// You can add more configuration exports here if needed
export default {
  API_BASE_URL
};
