import React, { useState, useEffect, useCallback } from 'react';
import LazyImage from '../components/LazyImage';
import Pagination from '../components/Pagination';
import ScrollToTop from '../components/ScrollToTop';
import Filters from '../components/Filters';
import { API_BASE_URL } from '../config';
import ImageModal from '../components/ImageModal';
import { useSearchParams } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
const BASE_URL_IMAGE_VIEWER = 'https://stpublicclient.blob.core.windows.net/teamhaven/Pictures/';

const ConfigModal = ({ isOpen, onClose, config, onConfigChange }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Configuration</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <label className="text-sm font-medium text-gray-700">
              Ajouter les métadonnées sur les images exportées
            </label>
            <button
              onClick={() => onConfigChange('addMetadata', !config.addMetadata)}
              className={`${
                config.addMetadata ? 'bg-blue-600' : 'bg-gray-200'
              } relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full transition-colors duration-200 ease-in-out`}
            >
              <span
                className={`${
                  config.addMetadata ? 'translate-x-6' : 'translate-x-1'
                } inline-block h-4 w-4 transform rounded-full bg-white transition duration-200 ease-in-out mt-1`}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const LoadingIndicator = () => (
  <div className="max-w-7xl mx-auto p-2 sm:p-4">
    <div className="animate-pulse">
      {/* Header skeleton */}
      <div className="mb-4">
        <div className="h-8 w-32 bg-gray-200 rounded mb-2"></div>
        <div className="h-4 w-64 bg-gray-200 rounded"></div>
      </div>

      {/* Filters skeleton */}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-1.5 mb-4">
        {[...Array(8)].map((_, i) => (
          <div key={i} className="mb-1">
            <div className="h-3 w-20 bg-gray-200 rounded mb-1"></div>
            <div className="h-[30px] bg-gray-200 rounded"></div>
          </div>
        ))}
      </div>

      {/* Pictures grid skeleton */}
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {[...Array(8)].map((_, i) => (
          <div key={i} className="border rounded-lg overflow-hidden">
            <div className="h-48 bg-gray-200"></div>
            <div className="p-4">
              <div className="h-4 w-3/4 bg-gray-200 rounded mb-2"></div>
              <div className="h-3 w-1/2 bg-gray-200 rounded mb-1"></div>
              <div className="h-3 w-1/3 bg-gray-200 rounded"></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

function Gallery() {
  const [searchParams, setSearchParams] = useSearchParams();

  // 1. First, declare constants that don't depend on anything
  const allowedColumns = [
    'username',
    'projectname',
    'shopsign',
    'shopname',
    'shopzipcode',
    'question',
    'item',
  ];

  // 2. Then declare functions that use those constants
  const getDefaultDateRange = () => {
    const end = new Date();
    const start = new Date();
    start.setFullYear(end.getFullYear() - 1);

    return {
      startDate: start.toISOString().split('T')[0],
      endDate: end.toISOString().split('T')[0]
    };
  };

  const getDefaultFilters = () => {
    return {
      createdat: getDefaultDateRange()
    };
  };

  const getInitialFilters = () => {
    const urlFilters = {};

    // Handle date filter
    const startDate = searchParams.get('startDate');
    const endDate = searchParams.get('endDate');

    if (startDate && endDate) {
      urlFilters.createdat = { startDate, endDate };
    } else {
      urlFilters.createdat = getDefaultDateRange();
    }

    // Handle favorite filter
    const favorite = searchParams.get('favorite');
    if (favorite === 'true') {
      urlFilters.favorite = true;
    }

    // Handle other filters
    allowedColumns.forEach(column => {
      const value = searchParams.get(column);
      if (value) {
        urlFilters[column] = { value, label: value };
      }
    });

    return urlFilters;
  };

  // 3. Then declare state variables
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('authToken'));
  const [loginError, setLoginError] = useState('');
  const [pictures, setPictures] = useState([]);
  const [dimensions, setDimensions] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [filters, setFilters] = useState(getInitialFilters());
  const [paginationInfo, setPaginationInfo] = useState({
    total: 0,
    page: 1,
    limit: 100,
    totalPages: 0
  });
  const [selectedPictures, setSelectedPictures] = useState([]);
  const [isExporting, setIsExporting] = useState(false);
  const [contentLoading, setContentLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [isConfigOpen, setIsConfigOpen] = useState(false);
  const [config, setConfig] = useState(() => {
    const savedConfig = localStorage.getItem('galleryConfig');
    return savedConfig ? JSON.parse(savedConfig) : { addMetadata: true };
  });

  // 4. Define fetchWithAuth callback
  const fetchWithAuth = useCallback(async (url, options = {}) => {
    const token = localStorage.getItem('authToken');
    const response = await fetch(url, {
      ...options,
      headers: {
        ...options.headers,
        'Authorization': `Bearer ${token}`
      }
    });

    if (response.status === 401 || response.status === 403) {

      if (!options.noLogOut) {
        setIsAuthenticated(false);
        localStorage.removeItem('authToken');
      }

      throw new Error('Authentication failed');
    }

    return response;
  }, []);

  // 5. Define fetchDimensions function
  const fetchDimensions = useCallback(async () => {
    try {
      // Create URL params from current filters
      const params = new URLSearchParams();
      Object.entries(filters).forEach(([key, value]) => {
        if (key === 'createdat' && value) {
          params.append(key, JSON.stringify({
            startDate: value.startDate,
            endDate: value.endDate
          }));
        } else if (key === 'favorite') {
          if (value === true) {
            params.append(key, 'true');
          }
        } else if (value?.value) {
          params.append(key, value.value);
        }
      });

      const dimensionPromises = allowedColumns.map(async (column) => {
        const response = await fetchWithAuth(
          `${API_BASE_URL}/api/columns/${column}?${params.toString()}`
        );
        const values = await response.json();
        return [column, values.map(value => ({ value, label: value }))];
      });

      const results = await Promise.all(dimensionPromises);
      setDimensions(Object.fromEntries(results));
      setLoading(false);
    } catch (error) {
      console.error('Error fetching dimensions:', error);
      setLoading(false);
    }
  }, [filters, fetchWithAuth]); // Add dependencies

  // 6. Add the useEffect for dimensions
  useEffect(() => {
    if (isAuthenticated) {
      fetchDimensions();
    }
  }, [isAuthenticated, fetchDimensions]);

  // 7.Update URL when filters change
  useEffect(() => {
    const params = new URLSearchParams();
    // Add current filters to URL
    Object.entries(filters).forEach(([key, value]) => {
      if (key === 'createdat' && value) {
        params.set('startDate', value.startDate);
        params.set('endDate', value.endDate);
      } else if (key === 'favorite') {
        if (value === true) {
          params.set('favorite', 'true');
        }
      } else if (value?.value) {
        params.set(key, value.value);
      }
    });
    // Add pagination
    params.set('page', paginationInfo.page.toString());
    // Update URL without reloading the page
    setSearchParams(params, { replace: true });
  }, [filters, paginationInfo.page, setSearchParams]);

  const login = async (username, password) => {
    try {
      // Create Base64 token
      const token = btoa(`${username}:${password}`);

      // Test the credentials with a simple API call
      const response = await fetch(`${API_BASE_URL}/api/pictures?limit=1`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        localStorage.setItem('authToken', token);
        setIsAuthenticated(true);
        setLoginError('');
      } else {
        setLoginError('Invalid credentials');
        localStorage.removeItem('authToken');
      }
    } catch (error) {
      console.error('Login error:', error);
      setLoginError('Login failed');
      localStorage.removeItem('authToken');
    }
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    setIsAuthenticated(false);
  };

  // Fetch dimension values for filters
  useEffect(() => {
    const fetchDimensions = async () => {
      try {
        // Create URL params from current filters
        const params = new URLSearchParams();
        Object.entries(filters).forEach(([key, value]) => {
          if (key === 'createdat' && value) {
            params.append(key, JSON.stringify({
              startDate: value.startDate,
              endDate: value.endDate
            }));
          } else if (key === 'favorite') {
            if (value === true) {
              params.append(key, 'true');
            }
          } else if (value?.value) {
            params.append(key, value.value);
          }
        });

        const dimensionPromises = allowedColumns.map(async (column) => {
          const response = await fetchWithAuth(
            `${API_BASE_URL}/api/columns/${column}?${params.toString()}`
          );
          const values = await response.json();
          return [column, values.map(value => ({ value, label: value }))];
        });

        const results = await Promise.all(dimensionPromises);
        setDimensions(Object.fromEntries(results));
        setLoading(false);
      } catch (error) {
        console.error('Error fetching dimensions:', error);
        setLoading(false);
      }
    };

    if (isAuthenticated) {
      fetchDimensions();
    }
  }, [filters, isAuthenticated]);

  // Update fetchPictures effect
  useEffect(() => {
    const fetchPictures = async () => {
      try {
        // Only show content loading after initial load
        if (!initialLoading) {
          setContentLoading(true);
        }

        const params = new URLSearchParams();

        Object.entries(filters).forEach(([key, value]) => {
          if (key === 'createdat' && value) {
            params.append('createdat[startDate]', value.startDate);
            params.append('createdat[endDate]', value.endDate);
          } else if (key === 'favorite') {
            if (value === true) {
              params.append('favorite', 'true');
            }
          } else if (value?.value) {
            params.append(key, value.value);
          }
        });

        params.append('page', paginationInfo.page);
        params.append('limit', paginationInfo.limit);

        const response = await fetchWithAuth(`${API_BASE_URL}/api/pictures?${params}`);
        const data = await response.json();
        setPictures(data.pictures || []);
        setPaginationInfo(prev => ({
          ...prev,
          ...data.pagination
        }));
      } catch (error) {
        console.error('Error fetching pictures:', error);
      } finally {
        setContentLoading(false);
        setInitialLoading(false);
      }
    };

    if (isAuthenticated) {
      fetchPictures();
    }
  }, [filters, paginationInfo.page, paginationInfo.limit, isAuthenticated, fetchWithAuth, initialLoading]);

  console.log('filters', filters);

  // Update handleFilterChange to maintain URL sync
  const handleFilterChange = useCallback((column, selectedOption) => {
    setPaginationInfo(prev => ({
      ...prev,
      page: 1
    }));

    setFilters(prev => {
      if (column === 'createdat') {
        if (!selectedOption) {
          const { createdat, ...rest } = prev;
          return rest;
        }
        return {
          ...prev,
          createdat: {
            startDate: selectedOption.startDate,
            endDate: selectedOption.endDate
          }
        };
      } else if (column === 'favorite') {
        if (!selectedOption) {
          const { favorite, ...rest } = prev;
          return rest;
        }
        return {
          ...prev,
          favorite: selectedOption
        };
      } else {
        if (!selectedOption) {
          const { [column]: removed, ...rest } = prev;
          return rest;
        }
        return {
          ...prev,
          [column]: selectedOption
        };
      }
    });
  }, []);

  // Handle ESC key press
  const handleEscKey = useCallback((event) => {
    if (event.key === 'Escape') {
      setSelectedImage(null);
    }
  }, []);

  // Add/remove event listener for ESC key
  useEffect(() => {
    if (selectedImage) {
      document.addEventListener('keydown', handleEscKey);
      return () => document.removeEventListener('keydown', handleEscKey);
    }
  }, [selectedImage, handleEscKey]);

  // Add this new function inside the Gallery component
  const handlePageChange = (newPage) => {
    setPaginationInfo(prev => ({
      ...prev,
      page: newPage
    }));
  };

  const handleSelectPicture = (pictureId) => {
    setSelectedPictures(prev => {
      if (prev.includes(pictureId)) {
        return prev.filter(id => id !== pictureId);
      }
      return [...prev, pictureId];
    });
  };

  const handleSelectAll = () => {
    if (selectedPictures.length === pictures.length) {
      setSelectedPictures([]);
    } else {
      setSelectedPictures(pictures.map(p => p.id));
    }
  };

  const handleClearSelection = () => {
    setSelectedPictures([]);
  };

  const handleExportSelected = async () => {
    if (selectedPictures.length === 0) return;

    setIsExporting(true);
    try {
      const token = localStorage.getItem('authToken');
      const response = await fetch(`${API_BASE_URL}/api/export-pictures`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          pictureIds: selectedPictures,
          addMetadata: config.addMetadata
        }),
      });

      if (!response.ok) throw new Error('Export failed');

      const { downloadUrl } = await response.json();

      // Create a hidden anchor element for download
      const link = document.createElement('a');
      link.href = `${window.location.origin}/downloads/${downloadUrl}`;
      link.target = '_blank';
      link.rel = 'noopener noreferrer'; // Security best practice
      link.style.display = 'none';
      document.body.appendChild(link);

      // Trigger click and clean up
      link.click();
      document.body.removeChild(link);

    } catch (error) {
      console.error('Export error:', error);
      // You might want to add error handling UI here
    } finally {
      setIsExporting(false);
    }
  };

  // Update handleToggleFavorite to modify the picture's favorite status
  const handleToggleFavorite = useCallback(async (pictureId, favorite) => {
    try {
      const response = await fetchWithAuth(
        `${API_BASE_URL}/api/pictures/${pictureId}/favorite`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ favorite })
        }
      );

      if (response.ok) {
        // Update the favorite status in both pictures and selectedImage
        setPictures(prev => prev.map(pic =>
          pic.id === pictureId ? { ...pic, favorite } : pic
        ));

        if (selectedImage?.id === pictureId) {
          setSelectedImage(prev => ({ ...prev, favorite }));
        }
      }
    } catch (error) {
      console.error('Error toggling favorite:', error);
    }
  }, [fetchWithAuth, selectedImage]);

  // Add a content loading indicator component
  const ContentLoadingIndicator = () => (
    <div className="absolute inset-0 bg-white bg-opacity-75 flex items-center justify-center z-10">
      <div className="animate-pulse flex flex-col items-center">
        <div className="w-16 h-16 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
        <span className="mt-2 text-sm text-gray-600">Chargement...</span>
      </div>
    </div>
  );

  // Add reset filters function
  const handleResetFilters = useCallback(() => {
    // Reset filters to only include default date range
    setFilters({
      createdat: getDefaultDateRange()
    });

    // Reset pagination to page 1
    setPaginationInfo(prev => ({
      ...prev,
      page: 1
    }));

    // Clear URL params except for page=1
    const params = new URLSearchParams();
    params.set('page', '1');
    params.set('startDate', getDefaultDateRange().startDate);
    params.set('endDate', getDefaultDateRange().endDate);
    setSearchParams(params, { replace: true });
  }, [setSearchParams]);

  const getCurrentImageIndex = () => {
    return pictures.findIndex(p => p.id === selectedImage.id);
  };

  const handlePreviousImage = () => {
    const currentIndex = getCurrentImageIndex();
    if (currentIndex > 0) {
      setSelectedImage(pictures[currentIndex - 1]);
    }
  };

  const handleNextImage = () => {
    const currentIndex = getCurrentImageIndex();
    if (currentIndex < pictures.length - 1) {
      setSelectedImage(pictures[currentIndex + 1]);
    }
  };

  const handleDeletePicture = async (pictureId) => {
    try {
      await fetchWithAuth(
        `${API_BASE_URL}/api/pictures/${pictureId}/delete`, { method: 'POST', noLogOut: true }
      );
      // Remove the picture from the local state
      setPictures(pictures.filter(p => p.id !== pictureId));
    } catch (error) {
      console.error('Error deleting picture:', error);
      throw error;
    }
  };

  const handleConfigChange = (key, value) => {
    const newConfig = { ...config, [key]: value };
    setConfig(newConfig);
    localStorage.setItem('galleryConfig', JSON.stringify(newConfig));
  };

  if (!isAuthenticated) {
    return (
      <div className="max-w-md mx-auto mt-10 p-6 bg-white rounded-lg shadow-lg">
        <h1 className="text-2xl font-bold mb-6">Login</h1>
        <form onSubmit={(e) => {
          e.preventDefault();
          const username = e.target.username.value;
          const password = e.target.password.value;
          login(username, password);
        }}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
              Username
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="username"
              type="text"
              placeholder="Username"
            />
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
              Password
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              type="password"
              placeholder="Password"
            />
          </div>
          {loginError && (
            <div className="mb-4 text-red-500 text-sm">
              {loginError}
            </div>
          )}
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Sign In
          </button>
        </form>
      </div>
    );
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <div className="max-w-7xl mx-auto p-2 sm:p-4 relative">
      <button
        onClick={() => setIsConfigOpen(true)}
        className="absolute top-4 right-2 p-2 text-gray-500 hover:text-gray-700"
        title="Configuration"
      >
        <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          />
        </svg>
      </button>

      <ConfigModal
        isOpen={isConfigOpen}
        onClose={() => setIsConfigOpen(false)}
        config={config}
        onConfigChange={handleConfigChange}
      />

      {initialLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          {/* Header and controls */}
          <div className="flex flex-col gap-2 mb-4">
            {/* Title and counts - reduced margins */}
            <div>
              <p className="text-xs sm:text-sm text-gray-600 mt-1">
                {/* Affichage de {pictures.length} sur {paginationInfo.total} résultats
                {Object.keys(filters).length > 0 && ' (filtrés)'} */}
                {selectedPictures.length > 0 && `${selectedPictures.length} selectionné(s)`}
              </p>
            </div>

            {/* Controls - more compact layout */}
            <div className="flex flex-wrap gap-1 text-xs sm:text-sm">
              <button
                onClick={handleSelectAll}
                className="bg-blue-500 hover:bg-blue-700 text-white py-0.5 px-2 rounded"
              >
                {selectedPictures.length === pictures.length ? 'Deselectionner' : 'Selectionner visibles'}
              </button>
              {selectedPictures.length > 0 && (
                <>
                  <button
                    onClick={handleClearSelection}
                    className="bg-gray-500 hover:bg-gray-700 text-white py-0.5 px-2 rounded"
                  >
                    Effacer la sélection
                  </button>
                  <button
                    onClick={handleExportSelected}
                    disabled={isExporting}
                    className="bg-green-500 hover:bg-green-700 text-white py-0.5 px-2 rounded disabled:bg-green-300"
                  >
                    {isExporting ? 'Exportation...' : 'Exporter la sélection'}
                  </button>
                </>
              )}
              <button
                onClick={logout}
                className="bg-red-500 hover:bg-red-700 text-white py-0.5 px-2 rounded"
              >
                Déconnexion
              </button>
            </div>
          </div>

          {/* Filters - always interactive */}
          <div className="mb-4">
            <div className="flex justify-between items-center mb-2">
              <h2 className="text-lg font-medium">Filtres</h2>
              {!isEqual(filters, getDefaultFilters()) && (
                <button
                  onClick={handleResetFilters}
                  className="text-sm text-blue-600 hover:text-blue-800 font-medium"
              >
                  Réinitialiser
                </button>
              )}
            </div>
            <Filters
              dimensions={dimensions}
              filters={filters}
              handleFilterChange={handleFilterChange}
              allowedColumns={allowedColumns}
              fetchWithAuth={fetchWithAuth}
            />
          </div>

          {/* Pagination */}
          <Pagination
            paginationInfo={paginationInfo}
            onPageChange={handlePageChange}
            totalFilters={Object.keys(filters).length}
          />

          {/* Content area with relative positioning for loading overlay */}
          <div className="relative pt-4">
            {contentLoading && <ContentLoadingIndicator />}

            <div className={contentLoading ? 'opacity-50' : ''}>
              {pictures.length === 0 ? (
                /* No results message */
                <div className="flex flex-col items-center justify-center py-12 px-4">
                  <svg
                    className="w-24 h-24 text-gray-300 mb-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                      d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                    />
                  </svg>
                  <h3 className="text-lg font-medium text-gray-900 mb-1">
                    Aucun résultat trouvé
                  </h3>
                  <p className="text-gray-500 text-center max-w-md">
                    Aucune image ne correspond aux filtres sélectionnés. Essayez de modifier vos critères de recherche.
                  </p>
                  <button
                    onClick={handleResetFilters}
                    className="mt-4 text-sm text-blue-600 hover:text-blue-800 font-medium"
                  >
                    Réinitialiser les filtres
                  </button>
                </div>
              ) : (
                /* Pictures grid */
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                  {pictures.map((picture) => (
                    <div
                      key={picture.id}
                      className="relative border rounded-lg overflow-hidden hover:shadow-lg transition-shadow cursor-pointer"
                      onClick={() => setSelectedImage(picture)}
                    >
                      {/* Top-left favorite heart */}
                      {picture.favorite && (
                        <div className="absolute top-2 left-2 z-10 bg-black bg-opacity-50 rounded-full p-1">
                          <svg
                            className="w-4 h-4 text-red-500 fill-current"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth="0"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                            />
                          </svg>
                        </div>
                      )}

                      {/* Top-right checkbox */}
                      <div
                        className="absolute top-2 right-2 z-10"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSelectPicture(picture.id);
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={selectedPictures.includes(picture.id)}
                          onChange={() => {}}
                          className="h-5 w-5 rounded border-gray-300"
                        />
                      </div>

                      <LazyImage
                        src={`${BASE_URL_IMAGE_VIEWER}${picture.pictureurl}`}
                        alt={picture.shopname}
                        className="w-full h-48 object-cover hover:opacity-90 transition-opacity"
                      />
                      <div className="p-4">
                        <h3 className="font-semibold">{picture.shopname}</h3>
                        <p className="text-sm text-gray-600">{picture.shopcity}</p>
                        <p className="text-sm text-gray-600">{picture.username}</p>
                        <p className="text-xs text-gray-500 mt-1">
                          {new Date(picture.createdat).toLocaleDateString()}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          {/* Modal and ScrollToTop remain unchanged */}
          {selectedImage && (
            <ImageModal
              image={selectedImage}
              onClose={() => setSelectedImage(null)}
              onToggleFavorite={(pictureId) => handleToggleFavorite(pictureId, !selectedImage.favorite)}
              onDelete={handleDeletePicture}
              BASE_URL_IMAGE_VIEWER={BASE_URL_IMAGE_VIEWER}
              onPrevious={handlePreviousImage}
              onNext={handleNextImage}
              hasPrevious={getCurrentImageIndex() > 0}
              hasNext={getCurrentImageIndex() < pictures.length - 1}
            />
          )}
          <ScrollToTop />
        </>
      )}
    </div>
  );
}

export default Gallery;