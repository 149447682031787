import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './pages/Home';
import Gallery from './pages/Gallery';

function App() {
  return (
    <Router>
      <div>
        {/* Navigation */}
        <nav className="bg-gray-800 p-4">
          <div className="container mx-auto flex justify-between items-center max-w-7xl">
            <div className="flex items-center space-x-4">
              <img src="/logo_shop_me_more.png" alt="TechSell Logo" className="h-14" />
              <img src="/logo_xiaomi.png" alt="Xiaomi Logo" className="h-14" />
            </div>
            <div className="flex space-x-4">
              {/* <Link to="/" className="text-white hover:text-gray-300">Home</Link>
              <Link to="/gallery" className="text-white hover:text-gray-300">Gallery</Link> */}
            </div>
          </div>
        </nav>

        {/* Routes */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/gallery" element={<Gallery />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;